export class LocalStorageManager {
    private readonly MAX_SIZE_BYTES = 4.5 * 1024 * 1024; // 4.5MB limit
    private readonly EXPIRY_MINUTES = 10080; // 7 days
    private readonly prefix: string;

    constructor(prefix: string) {
        this.prefix = prefix;
    }

    static create(prefix: string): LocalStorageManager {
        return new LocalStorageManager(prefix);
    }

    setItem(key: string, value: any): void {
        this.cleanExpiredItems();

        if (this.getCurrentSize() > this.MAX_SIZE_BYTES) {
            this.removeOldestItems();
        }

        const item = {
            value,
            timestamp: new Date().getTime(),
            expiry: new Date().getTime() + this.EXPIRY_MINUTES * 60 * 1000,
        };

        localStorage.setItem(this.prefix + key, JSON.stringify(item));
    }

    getItem(key: string): any {
        const item = localStorage.getItem(this.prefix + key);
        if (!item) return null;

        const parsed = JSON.parse(item);
        if (parsed.expiry && new Date().getTime() > parsed.expiry) {
            localStorage.removeItem(this.prefix + key);
            return null;
        }

        return parsed.value;
    }

    removeItem(key: string): void {
        localStorage.removeItem(this.prefix + key);
    }

    private cleanExpiredItems(): void {
        Object.keys(localStorage)
            .filter((key) => key.startsWith(this.prefix))
            .forEach((key) => {
                const item = JSON.parse(localStorage.getItem(key) || "{}");
                if (item.expiry && new Date().getTime() > item.expiry) {
                    localStorage.removeItem(key);
                }
            });
    }

    private getCurrentSize(): number {
        let size = 0;
        Object.keys(localStorage)
            .filter((key) => key.startsWith(this.prefix))
            .forEach((key) => {
                size += localStorage.getItem(key)?.length || 0;
            });
        return size * 2;
    }

    private removeOldestItems(): void {
        const items = Object.keys(localStorage)
            .filter((key) => key.startsWith(this.prefix))
            .map((key) => ({
                key,
                timestamp: JSON.parse(localStorage.getItem(key) || "{}")
                    .timestamp,
            }))
            .sort((a, b) => a.timestamp - b.timestamp);

        while (
            this.getCurrentSize() > this.MAX_SIZE_BYTES * 0.8 &&
            items.length
        ) {
            const oldest = items.shift();
            if (oldest) {
                localStorage.removeItem(oldest.key);
            }
        }
    }
}

export const LocalStorage_Prefix = {
    App: "RaiseDonorsApp_",
    User: "RaiseDonorsUser_",
    Preview: "RaiseDonorsPreview_",
};
