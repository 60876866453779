export enum Frequency {
    OneTime = 0,
    Weekly = 52,
    EveryOtherWeek = 26,
    Monthly = 12,
    Quarterly = 4,
    BiAnnualy = 2,
    Annualy = 1,
}

export const frequencyDisplayName = (frequency: Frequency) => {
    switch (frequency) {
        case Frequency.OneTime:
            return "One-Time";
        case Frequency.Weekly:
            return "Weekly";
        case Frequency.Monthly:
            return "Monthly";
        case Frequency.EveryOtherWeek:
            return "Every Other Week";
        case Frequency.Quarterly:
            return "Quarterly";
        case Frequency.BiAnnualy:
            return "Every Six Months";
        case Frequency.Annualy:
            return "Annually";
        default:
            return "One-Time";
    }
};

export const stringToFrequency = (value: string) => {
    switch (value.trim().toLowerCase()) {
        case "one-time":
        case "onetime":
        case "one time":
            return Frequency.OneTime;
        case "week":
        case "weekly":
        case "every week":
        case "every-week":
        case "everyweek":
            return Frequency.Weekly;
        case "month":
        case "monthly":
        case "every month":
        case "every-month":
            return Frequency.Monthly;
        case "everyotherweek":
        case "every other week":
        case "every other-week":
        case "every-other-week":
        case "every otherweek":
        case "every-otherweek":
        case "every 2 weeks":
            return Frequency.EveryOtherWeek;
        case "quarter":
        case "quarterly":
        case "every three months":
        case "every-three-months":
        case "every 3 months":
        case "every-3-months":
        case "everyquarter":
        case "every-quarter":
            return Frequency.Quarterly;
        case "biannual":
        case "biannually":
        case "bi-annual":
        case "bi-annually":
        case "every six months":
        case "every-six-months":
        case "every 6 months":
        case "every-6-months":
        case "everysixmonths":
            return Frequency.BiAnnualy;
        case "year":
        case "yearly":
        case "annual":
        case "annually":
        case "every year":
        case "every-year":
            return Frequency.Annualy;
        default:
            return Frequency.OneTime;
    }
};
