import { LocalStorageManager } from "@/utilities/LocalStorageManager";

export class BaseStore {
    localStorageManager: LocalStorageManager;

    constructor(prefix: string) {
        this.localStorageManager = new LocalStorageManager(prefix);
    }

    initLocalStorageManager(prefix: string) {
        this.localStorageManager = new LocalStorageManager(prefix);
    }
}
